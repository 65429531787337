import React from 'react'
import { Link } from 'gatsby'
import profileGubler from '../images/profile-gubler.jpeg'

import Layout from '../components/layout'

const AdminPage = () => (
  <Layout>
    <h2 className="major">Administration</h2>
    <h3 id="Gubler">Shandon Gubler, Ph.D.</h3>
    <img width="20%" src={profileGubler} alt="" />
    <p>
      Shandon Gubler, PhD. is well recognized in the field of education. He
      received his PhD. at Brigham Young University and has dedicated much of
      his life to academia. As Professor at BYU’s Marriott Business School he
      received the Excellence in Teaching Award, and at Dixie State University
      he received the Distinguished Teaching Award. He has founded several
      ventures as an Angel investor, and was co-founder of Agilix Labs.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AdminPage
